import { graphql, useStaticQuery } from 'gatsby'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import Img from 'gatsby-image/withIEPolyfill'
import InputGroup from 'src/components/InputGroup'
import { MainCta } from 'src/components/Button'
import React from 'react'
import LeadCapture from 'src/components/LeadCapture/LeadCapture'

const HvcoLeadCapture = React.forwardRef((props, ref) => {
  const formRef = ref
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hvco-why-cant-lose-weight.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <LeadCapture
      title={
        <>
          Learn the <span css={{ textDecoration: 'underline' }}>ONE</span>{' '}
          reason why you just can't lose weight. FREE.
        </>
      }
      bullets={
        <>
          <Typography variant={'h6'} as={'p'} mb={'0.5em'}>
            Struggling to lose weight? Always feeling hungry? Keep losing
            motivation?
          </Typography>
          <Typography variant={'h6'} as={'p'}>
            In this <strong>FREE eBook</strong>, you will:
          </Typography>
          <Box as={'ul'} css={{ paddingInlineStart: '24px' }}>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn why <strong>everything you've tried</strong> so far is
              working completely <strong>against how your body works.</strong>
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn the one <strong>one bulletproof method</strong> that has
              stood the test of time.
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn how to{' '}
              <strong>
                kick-start your metabolic engine into a fat-burning machine!
              </strong>
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn how to completely{' '}
              <strong>lose hunger cravings while feeling amazing 24/7!</strong>
            </Typography>
            <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
              Learn how you can be successful in weight-loss{' '}
              <strong>without spending hours in the gym!</strong>
            </Typography>
          </Box>
        </>
      }
      rhs={
        <Box width={{ md: '100%' }}>
          <Img fluid={data.file.childImageSharp.fluid} objectFit={'cover'} />
        </Box>
      }
      formName={'hvco-lead-capture'}
      formAction={'/thank-you/hvco'}
      formFields={
        <>
          <InputGroup
            name={'full-name'}
            id={'full-name'}
            labelText={'Full Name'}
            placeholder={'Full Name'}
            type={'text'}
            ref={formRef}
          />
          <InputGroup
            name={'email'}
            id={'email'}
            labelText={'Email'}
            placeholder={'Email'}
            type={'email'}
          />
          <MainCta
            width={1}
            mainContent={'Download Free eBook'}
            subtitleText={`Yes, I'm tired of trying too hard!`}
          />
          <Typography variant={'subnote'} textAlign={'center'} mt={'0.5em'}>
            Sent right to your inbox!
          </Typography>
        </>
      }
    />
  )
})

export default HvcoLeadCapture
