import React, { useRef } from 'react'
import Box from '@local/shared/components/System/Box'
import Typography from '@local/shared/components/System/Typography'
import NetlifyForm from 'src/components/NetlifyForm'
import serialize from 'form-serialize'
import { navigate } from '@reach/router'
import { Waypoint } from 'react-waypoint'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const FLEX_BREAKPOINT = 'md'

const AForm = React.forwardRef(({ name, action, onSubmit, children }, ref) => {
  return (
    <Box
      as={NetlifyForm}
      name={name}
      method="post"
      action={action}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      mt={'1em'}
      ref={ref}
    >
      {children}
    </Box>
  )
})

const LeadCapture = ({
  title,
  subtitle,
  bullets,
  rhs,
  formName,
  formFields,
  formAction,
  countdown,
  ...props
}) => {
  // const formRef = useRef(null)
  // const handleSubmit = async e => {
  //   e.preventDefault()
  //   const serializedForm = serialize(formRef.current)
  //   console.log('serializedForm', serializedForm)
  //   await fetch('/', {
  //     method: 'post',
  //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //     body: serializedForm,
  //   })
  //   const formValesObj = serialize(formRef.current, { hash: true })
  //   return navigate(formAction, { state: formValesObj })
  // }
  const mixpanel = useMixpanel()
  return (
    <Waypoint
      onEnter={() => mixpanel.track('Entered Section', { name: 'leadCapture' })}
    >
      <Box
        pt={'2em'}
        pb={{ xs: '2em', md: '2em' }}
        borderBottom={'solid 0.5em'}
        borderColor={'blue.light1'}
        css={{ overflow: 'hidden' }}
        bgcolor={{ xs: 'white', sm: 'blue.dark0' }}
        mx={'auto'}
        px={'1em'}
      >
        <Box
          mx={'auto'}
          maxWidth={800}
          bgcolor={'white'}
          borderRadius={20}
          borderColor={'blue.0'}
          p={{ sm: '2em' }}
        >
          {subtitle && (
            <Typography
              variant={'h6'}
              mt={'0em'}
              mb={'1em'}
              css={{ textTransform: 'uppercase' }}
              textAlign={'center'}
            >
              {subtitle}
            </Typography>
          )}
          <Typography variant={'h1'} as={'h2'} mb={'1em'} textAlign={'center'}>
            {title}
          </Typography>

          <Box
            display={{ [FLEX_BREAKPOINT]: 'flex' }}
            alignItems={'flex-start'}
          >
            <Box
              width={{ [FLEX_BREAKPOINT]: 0.5 }}
              mr={{ [FLEX_BREAKPOINT]: '2em' }}
            >
              {bullets}

              <Box
                display={{ [FLEX_BREAKPOINT]: 'none' }}
                maxWidth={400}
                mx={'auto'}
                py={'1em'}
              >
                {rhs}
              </Box>
            </Box>
            <Box
              display={{ xs: 'none', [FLEX_BREAKPOINT]: 'block' }}
              width={0.5}
            >
              {rhs}
            </Box>
          </Box>
          <AForm name={formName} action={formAction}>
            {formFields}
          </AForm>
          {countdown && countdown}
        </Box>
      </Box>
    </Waypoint>
  )
}

export default LeadCapture
