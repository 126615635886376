import React from 'react'
import { Input } from 'src/components/Input'
import styled from 'styled-components'

const Label = styled.label`
  display: none;
`

const InputGroup = React.forwardRef((props, ref) => {
  const { id, labelText, name, type, placeholder } = props
  return (
    <>
      <Label htmlFor={id}>{labelText}</Label>
      <Input
        id={id}
        name={name}
        display={'block'}
        width={1}
        type={type}
        placeholder={placeholder}
        ref={ref}
        required
      />
    </>
  )
})

export default InputGroup
