import React from 'react'
import { Typography } from '@local/shared/components/System'
import styled from 'styled-components'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const InputStyled = styled(Typography)`
  ::placeholder {
    color: ${({ theme }) => theme.palette.text.placeholder};
  }
  :hover,
  :active,
  :focus {
    // background-color: ${({ theme }) => theme.palette.blue.light2};
    background-color: white;
    border-color: ${({ theme }) => theme.palette.blue.dark0}aa;
    outline: none;
  }
`

const Input = React.forwardRef((props, ref) => {
  const mixpanel = useMixpanel()
  return (
    <InputStyled
      variant={'subtitle'}
      forwardedAs={'input'}
      p={'1rem'}
      mb={'0.5em'}
      border={'solid 0.25rem'}
      borderColor={'blue.dark0'}
      bgcolor={'blue.light2'}
      borderRadius={0}
      onFocus={e => {
        mixpanel.track('Input Focus', { name: props.name })
        props.onFocus && props.onFocus(e)
      }}
      onChange={e => {
        mixpanel.track('Input Change', { name: props.name })
        props.onChange && props.onChange(e)
      }}
      ref={ref}
      {...props}
    />
  )
})

const TextArea = props => <Input forwardedAs={'textarea'} {...props} />

export { Input, TextArea }
