import React from 'react'
import Box from '@local/shared/components/System/Box'
import Typography from '@local/shared/components/System/Typography'

const HeaderTitle = ({ children }) => (
  <Typography
    variant={'h4'}
    mb={'1em'}
    css={{ textTransform: 'uppercase', fontWeight: 'normal' }}
  >
    {children}
  </Typography>
)

const BoldTextSection = ({
  title,
  heading,
  subheading,
  bgcolor = 'white',
  borderColor = 'transparent',
  color = 'inherit',
  ...props
}) => {
  return (
    <Box
      bgcolor={bgcolor}
      borderTop={'solid 0.5em'}
      borderColor={borderColor}
      p={'2em'}
      py={'2em'}
      color={color}
      {...props}
    >
      <Box maxWidth={'800px'} margin={'auto'} textAlign={'center'}>
        <HeaderTitle>{title}</HeaderTitle>
        <Typography variant={'h1'} mb={'0.5em'} as={'p'}>
          {heading}
        </Typography>
        <Typography variant={'subtitle'} as={'p'}>
          {subheading}
        </Typography>
      </Box>
    </Box>
  )
}

export default BoldTextSection
