import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HvcoLandingPage from 'src/components/LandingPage/HvcoLandingPage'

const GeneralHvcoLandingPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      hero1: file(relativePath: { eq: "hvco-why-cant-lose-weight.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const heroProps = {
    titleText: `Finally: A Weight-Loss Method That Actually Works!`,
    subtitleText: `This is the information that the health industry doesn't want you to know!`,
    image: data.hero1.childImageSharp.fluid,
  }

  return <HvcoLandingPage heroProps={heroProps} location={location} />
}

export default GeneralHvcoLandingPage
