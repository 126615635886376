import React from 'react'
import Seo from 'src/components/Seo'
import PageLayout from 'src/components/PageLayout'
import { EbookCta } from 'src/components/Button'
import Box from '@local/shared/components/System/Box'
import { Typography } from '@local/shared/components/System'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import BoldTextSection from 'src/components/BoldTextSection'
import HvcoLeadCapture from 'src/components/LeadCapture/HvcoLeadCapture'

const HvcoLandingPage = ({ location, heroProps }) => {
  const formRef = React.useRef(null)

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "hvco-why-cant-lose-weight.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageLayout>
      <Seo
        title="WildLife Weight Loss Coaching"
        description={
          'Lose weight, get fit and become a superhuman using tried-and-tested techniques used by our ancestors.'
        }
        shortTeaser={
          'Lose weight, get fit and become a superhuman using tried-and-tested techniques used by our ancestors.'
        }
        locationPathname={location.pathname}
      />
      <Box
        position={'relative'}
        css={{ overflow: 'hidden' }}
        borderTop={'solid 0.5em'}
        borderColor={'blue.light1'}
      >
        <Box
          mx={'auto'}
          px={'1em'}
          py={'2em'}
          maxWidth={'800px'}
          alignSelf={'flex-start'}
          textAlign={'center'}
        >
          <Typography variant={'h1'} mb={'0.5em'}>
            Revealed: The Reason Why You Just Can't Lose Weight!
          </Typography>
          <Typography variant={'subtitle'} mb={'2rem'}>
            We tell you everything the health and food industries have been
            hiding from us and how to fix yourself!
          </Typography>
        </Box>
        <Box pb={'2em'} maxWidth={500} mx={'auto'}>
          <Box as={Img} fluid={data.image.childImageSharp.fluid} zIndex={-1} />
        </Box>
      </Box>
      <EbookCta
        onClick={() => formRef.current.focus()}
        mx={'auto'}
        mb={'2em'}
      />

      <BoldTextSection
        title={`Dear Hangry Dieter`}
        heading={`Most people spend their entire life walking around like grumpy, starving zombies!`}
        subheading={`Well, that's until they give up, and put all the weight back on, and more!`}
        bgcolor={'blue.dark0'}
        color={'white'}
        borderColor={'blue.0'}
      />
      <BoldTextSection
        title={`Can't Lose Weight?`}
        heading={`Tired of walking 10,000 steps, gymming three-times a week and spending a fortune at health-food shops?`}
        subheading={`Sometimes you do everything “right” and after three months, have nothing to show for it!`}
        bgcolor={'blue.light2'}
        borderColor={'white'}
      />
      <BoldTextSection
        title={`Calorie Counting?`}
        heading={`It's hard to think of a more unnatural and unsustainable
                  relationship to food than counting calories.`}
        subheading={`But, you can teach your body how to magically manage your
                     portions for you!`}
        bgcolor={'blue.0'}
        borderColor={'blue.light1'}
      />
      <BoldTextSection
        title={`Tired of stepping on eggshells?`}
        heading={`“I find it exhausting constantly having to make decisions about
                  what food to eat and what food to avoid!”`}
        subheading={`Bad food is all around us, at work, when we
                     shop and at parties. It really does get tiring having to overcome
                     your natural urges to try all the things!`}
        bgcolor={'white'}
        borderColor={'blue.light2'}
        borderBottom={'solid 0.5em'}
      />
      <HvcoLeadCapture ref={formRef} />
    </PageLayout>
  )
}

export default HvcoLandingPage
